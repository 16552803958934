<template>
  <div>
    <v-card elevation="1">
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="beforeSearch">
          <v-row>
            <v-col cols="12">
              <v-autocomplete v-model="menu" hide-details="auto" slot="selection" :items="listMenuDelete" item-value="actionId" :item-text="(item) => item.actionId + ' - ' + item.actionName" :label="this.$t('typeDeleteSparePart')" :placeholder="this.$t('searchMenuDeleteSparePart')" required outlined dense clearable></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete v-model="company" hide-details="auto" slot="selection" :rules="companyRuls" :items="listAllCompany" item-value="companyId" :item-text="(item) => item.companyId + ' - ' + item.companyName" :label="this.$t('company')" :placeholder="this.$t('searchCompany')" required outlined dense clearable></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu v-model="menuStartDate" :close-on-content-click="false" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="getStartDateFormat" :label="$t('startDate')" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" v-on:click:append="menuStartDate = true" outlined dense hide-details required></v-text-field>
                </template>
                <v-date-picker v-model="startDate" @change="menuStartDate = false" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="startDate = getTodayDate($t('startDate'))">{{ $t("today") }}</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu v-model="menuEndDate" :close-on-content-click="false" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="getEndDateFormat" :label="$t('endDate')" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" v-on:click:append="menuEndDate = true" outlined dense hide-details required></v-text-field>
                </template>
                <v-date-picker v-model="endDate" @change="menuEndDate = false" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="endDate = getTodayDate($t('endDate'))">{{ $t("today") }}</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-btn color="primary" type="submit" class="col-sm-12 col-lg-2" :loading="isLoading" depressed>{{ $t("showInfo") }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DateConst from "@/constant/date.const";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SparePartFilter",
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      valid: true,
      companyRuls: [(v) => !!v || this.$t("msgError.plsSelectCompany")],
    };
  },
  mounted() {
    this.getListMenu();
  },
  computed: {
    company: {
      get() {
        return this.getCompanyId;
      },
      set(value) {
        this.setCompanyId(value);
      },
    },
    startDate: {
      get() {
        return this.getStartDate;
      },
      set(value) {
        this.setStartDate(value);
      },
    },
    endDate: {
      get() {
        return this.getEndDate;
      },
      set(value) {
        this.setEndDate(value);
      },
    },
    menu: {
      get() {
        return this.getMenuId;
      },
      set(value) {
        this.setMenuId(value);
      },
    },
    ...mapGetters({
      listAllCompany: "company/getAllCompany",
      getCompanyId: "sparepart/getCompanyId",
      getStartDateFormat: "sparepart/getStartDateFormat",
      getEndDateFormat: "sparepart/getEndDateFormat",
      getStartDate: "sparepart/getStartDate",
      getEndDate: "sparepart/getEndDate",
      isLoading: "sparepart/isLoading",
      listMenuDelete: "sparepart/getListMenu",
      getMenuId: "sparepart/getMenuId",
    }),
  },
  methods: {
    ...mapGetters({
      getUserProfile: "user/getUserProfile",
    }),
    ...mapActions({
      setCompanyId: "sparepart/setCompanyId",
      setStartDate: "sparepart/setStartDate",
      setEndDate: "sparepart/setEndDate",
      searchSparePartHistory: "sparepart/searchHistory",
      searchSparePartStock: "sparepart/searchStock",
      searchSparePartMaster: "sparepart/searchMaster",
      getListMenu: "sparepart/getListMenu",
      setMenuId: "sparepart/setMenuId",
    }),
    getTodayDate(menuDate) {
      if (menuDate === this.$t("startDate")) {
        this.menuStartDate = false;
        this.setStartDate(moment().format(DateConst.FORMAT_YYYY_MM_DD));
      } else if (menuDate === this.$t("endDate")) {
        this.menuEndDate = false;
        this.setEndDate(moment().format(DateConst.FORMAT_YYYY_MM_DD));
      }
    },
    async beforeSearch() {
      try {
        if (!this.$refs.form.validate()) {
          throw new Error();
        }
        const payload = {
          companyId: this.getCompanyId,
          startDate: this.getStartDate,
          endDate: this.getEndDate,
        };
        // แสดงผลตอนค้นหา ในแต่ละเมนูย่อยลบอะไหล่ 
        if (this.getMenuId == 2){ //! ลบประวัติเบิกอะไหล่
          this.searchSparePartHistory(payload);
        }else if (this.getMenuId == 3){  //! ลบสต๊อกอะไหล่และลบประวัติเบิกอะไหล่
          this.searchSparePartStock(payload);
        }else if (this.getMenuId == 4){   //! ลบรายชื่ออะไหล่
          this.searchSparePartMaster(payload);
        }
        
        
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
