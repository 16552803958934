import APIService from "./base/BaseAPIService";

export default {
  //! ลบประวัติเบิกอะไหล่
  getRequestHistoryByComId(companyId, startDate, endDate) {
    return APIService().get("/sparePart/company/" + companyId + "/getRequestHistory",
      {
        params: {
          start: startDate,
          end: endDate, 
        }
      });
  },
  removeAllRequesHistoryTask(companyId, startDate, endDate, payloads) {
    return APIService().delete("/sparePart/company/" + companyId + "/delete-request-fromStock", { params: { start: startDate, end: endDate }, data: payloads });
  },
  
  //! ลบสต๊อกอะไหล่
  getStockByComId(companyId, startDate, endDate) {
    return APIService().get("/sparePart/company/" + companyId + "/getStock",
      {
        params: {
          start: startDate,
          end: endDate, 
        }
      });
  },
  removeAllStockTask(companyId, startDate, endDate, payloads) {
    return APIService().delete("/sparePart/company/" + companyId + "/delete-all-stock", { params: { start: startDate, end: endDate }, data: payloads });
  },
  
  //! ลบรายชื่ออะไหล่
  getMasterByComId(companyId, startDate, endDate) {
    return APIService().get("/sparePart/company/" + companyId + "/getMaster",
      {
        params: {
          start: startDate,
          end: endDate, 
        }
      });
  },
  removeAllMasterTask(companyId, startDate, endDate, payloads) {
    return APIService().delete("/sparePart/company/" + companyId + "/delete-all-master", { params: { start: startDate, end: endDate }, data: payloads });
  },
};

