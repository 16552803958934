import DateConst from "@/constant/date.const";
import APISparePartService from "@/services/APISparePartService";
import { handleException } from "@/util/ExceptionUtil";
import moment from "moment";
import { SparePartMenuModel } from "@/models/SparePartMenuModel";
import APIMasterService from "@/services/APIMasterService";

const state = {
  companyId: null,
  startDateFormat: moment().format(DateConst.FORMAT_DD_MM_YYYY),
  endDateFormat: moment().format(DateConst.FORMAT_DD_MM_YYYY),
  startDate: moment().format(DateConst.FORMAT_YYYY_MM_DD),
  endDate: moment().format(DateConst.FORMAT_YYYY_MM_DD),
  listSparePartHistory: [],
  listSparePartStock: [],
  listSparePartMaster: [],
  isLoading: false,
  listMenu: [],
  menuId: null,
};

const getters = {
  isLoading: (state) => {
    return state.isLoading;
  },
  getListSparePartHistory: (state) => {
    return state.listSparePartHistory;
  },
  getListSparePartStock: (state) => {
    return state.listSparePartStock;
  },
  getListSparePartMaster: (state) => {
    return state.listSparePartMaster;
  },
  getCompanyId: (state) => {
    return state.companyId;
  },
  getStartDateFormat: (state) => {
    return state.startDateFormat;
  },
  getEndDateFormat: (state) => {
    return state.endDateFormat;
  },
  getStartDate: (state) => {
    return state.startDate;
  },
  getEndDate: (state) => {
    return state.endDate;
  },
  getListMenu: (state) => {
    return state.listMenu;
  },
  getMenuId: (state) => {
    return state.menuId;
  },
};

const actions = {
  setCompanyId({ commit }, companyId) {
    commit("SET_COMPANY_ID", companyId);
  },
  setMenuId({ commit }, menuId) {
    commit("SET_MENU_ID", menuId);
  },
  setStartDate({ commit }, startDate) {
    commit("SET_START_DATE_FORMAT", startDate);
    commit("SET_START_DATE", startDate);
  },
  setEndDate({ commit }, endDate) {
    commit("SET_END_DATE_FORMAT", endDate);
    commit("SET_END_DATE", endDate);
  },
  async searchHistory({ commit, state }, { companyId, startDate, endDate }) {
    commit("SEARCH_HISTORY", []);
    try {
      commit("SET_LOADING", true);
      const response = await APISparePartService.getRequestHistoryByComId(companyId, startDate, endDate);
      if (response.status !== 200) {
        throw new APIException(response.status, response.message);
      }
      commit("SEARCH_HISTORY", response.data);
    } catch (err) {
      handleException(err);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async searchStock({ commit, state }, { companyId, startDate, endDate }) {
    commit("SEARCH_STOCK", []);
    try {
      commit("SET_LOADING", true);
      const response = await APISparePartService.getStockByComId(companyId, startDate, endDate);
      if (response.status !== 200) {
        throw new APIException(response.status, response.message);
      }
      commit("SEARCH_STOCK", response.data);
    } catch (err) {
      handleException(err);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async searchMaster({ commit, state }, { companyId, startDate, endDate }) {
    commit("SEARCH_MASTER", []);
    try {
      commit("SET_LOADING", true);
      const response = await APISparePartService.getMasterByComId(companyId, startDate, endDate);
      if (response.status !== 200) {
        throw new APIException(response.status, response.message);
      }
      commit("SEARCH_MASTER", response.data);
    } catch (err) {
      handleException(err);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async getListMenu({ commit, state }) {
    console.log(`getListMenu`);
    if (state.listMenu.length === 0) {
      try {
        const response = await APIMasterService.getMenuDeleteSparePart();
        if (response.status !== 200) {
          throw new APIException(response.status, response.message);
        }
        const cvListMenu = [];
        response.data.forEach((menu) => cvListMenu.push(new SparePartMenuModel(menu.actionId, menu.actionName)));
        commit("SET_LIST_MENU", cvListMenu);
      } catch (err) {
        handleException(err);
      }
    }
  },
};

const mutations = {
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_COMPANY_ID(state, companyId) {
    state.companyId = companyId;
  },
  SET_MENU_ID(state, menuId) {
    state.menuId = menuId;
  },
  SET_START_DATE_FORMAT(state, startDateFormat) {
    state.startDateFormat = moment(startDateFormat).format("DD/MM/YYYY");
  },
  SET_END_DATE_FORMAT(state, endDateFormat) {
    state.endDateFormat = moment(endDateFormat).format("DD/MM/YYYY");
  },
  SET_START_DATE(state, startDate) {
    state.startDate = startDate;
  },
  SET_END_DATE(state, endDate) {
    state.endDate = endDate;
  },
  SET_LIST_MENU(state, listMenu) {
    state.listMenu = listMenu;
  },
  SEARCH_HISTORY(state, listSparePartHistory) {
    state.listSparePartHistory = listSparePartHistory;
  },
  SEARCH_STOCK(state, listSparePartStock) {
    state.listSparePartStock = listSparePartStock;
  },
  SEARCH_MASTER(state, listSparePartMaster) {
    state.listSparePartMaster = listSparePartMaster;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
