<template>
  <div id="sparePart" class="pa-5">
    <v-container>
      <v-row>
        <v-col>
          <SparePartFilter />
          
          <!-- ปุ่มลบอะไหล่ทั้งหมด -->
          <v-col v-if="getMenuId==2">
            <v-btn color="error"   class="col-sm-12 col-lg-2" @click.prevent="onPrepareRemoveAllHistoryTask()" depressed>
              {{ $t("deletedSparePartHistoryTaskAll") }}              
              </v-btn>
          </v-col>
          <v-col v-if="getMenuId==3">
            <v-btn color="error"   class="col-sm-12 col-lg-2" @click.prevent="onPrepareRemoveAllStockTask()" depressed>
              {{ $t("deletedSparePartStockTaskAll") }}               
              </v-btn>
          </v-col>
          <v-col v-if="getMenuId==4">
            <v-btn color="error"   class="col-sm-12 col-lg-2" @click.prevent="onPrepareRemoveAllMasterTask()" depressed>
              {{ $t("deletedSparePartMasterTaskAll") }}               
              </v-btn>
          </v-col>
          
          <!-- table -->
            <v-card-text>
              <v-row v-if="getMenuId==2"> <!-- เลือกเมนู ลบประวัติเบิกอะไหล่ -->
                <v-col> 
                  <label>{{ getMenuId }} ลบประวัติเบิกอะไหล่ => </label> <label>{{ getCompanyId }} => </label> <label>{{ getStartDate }}  => </label> <label>{{ getEndDate }}  => </label> <br>
                  <v-data-table v-model="tableSparePart" :loading-text="$t('loading')" :no-data-text="$t('msgError.dataNotFound')" :search="search"  :page="page" :headers="headersHistory" :items="listSparePartHistory" item-key="SPARE_PART_REQ_ID" :options.sync="options" :loading="isLoading" :footer-props="footerProps"></v-data-table>
                </v-col>
              </v-row>
              <v-row v-else-if="getMenuId==3"> <!-- เลือกเมนู ลบสต๊อกอะไหล่ -->
                <v-col> 
                  <label>{{ getMenuId }} ลบสต๊อกอะไหล่ => </label> <label>{{ getCompanyId }} => </label> <label>{{ getStartDate }}  => </label> <label>{{ getEndDate }}  => </label> <br>
                  <v-data-table v-model="tableSparePart" :loading-text="$t('loading')" :no-data-text="$t('msgError.dataNotFound')" :search="search" :page="page" :headers="headersStock" :items="listSparePartStock" item-key="SPARE_PART_STOCK_ID" :options.sync="options" :loading="isLoading" :footer-props="footerProps"></v-data-table>
                </v-col>
              </v-row>
              <v-row v-else-if="getMenuId==4"> <!-- เลือกเมนู ลบรายชื่ออะไหล่ -->
                <v-col> 
                  <label>{{ getMenuId }} ลบรายชื่ออะไหล่ => </label> <label>{{ getCompanyId }} => </label> <label>{{ getStartDate }}  => </label> <label>{{ getEndDate }}  => </label> <br>
                  <v-data-table v-model="tableSparePart" :loading-text="$t('loading')" :no-data-text="$t('msgError.dataNotFound')" :search="search"  :page="page" :headers="headersMaster" :items="listSparePartMaster" item-key="SPARE_PART_ID" :options.sync="options" :loading="isLoading" :footer-props="footerProps"></v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    
    <ConfirmDialog v-model="showConfirmDialogHistory.val" :msg="this.$t('confirmDialog.msgDefault')" :onConfirm="onRemoveAllSparePartHistoryTask" />
    <ConfirmDialog v-model="showConfirmDialogStock.val" :msg="this.$t('confirmDialog.msgDefault')" :onConfirm="onRemoveAllSparePartStockTask" />
    <ConfirmDialog v-model="showConfirmDialogMaster.val" :msg="this.$t('confirmDialog.msgDefault')" :onConfirm="onRemoveAllSparePartMasterTask" />
    
    <v-dialog v-model="dialogGetOtp" max-width="400" persistent>
      <template v-slot:activator="{}"></template>
      <v-card>
        <v-card-title>{{ this.$t("otpDialog.title") }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-otp-input v-model="userOtp"></v-otp-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">{{ this.$t("otpDialog.ref") }} : {{ userRef }}</v-col>
            </v-row>
          </v-container>
        </v-card-text>
        
        <v-card-actions v-if="getMenuId==2">
          <v-spacer></v-spacer>
          <v-btn text @click="close">{{ this.$t("confirmDialog.no") }}</v-btn>
          <v-btn color="primary" text @click="confirmDeleteHistory">
            <b>{{ this.$t("confirmDialog.yes") }}</b>
          </v-btn>
        </v-card-actions>
        
        <v-card-actions v-if="getMenuId==3">
          <v-spacer></v-spacer>
          <v-btn text @click="close">{{ this.$t("confirmDialog.no") }}</v-btn>
          <v-btn color="primary" text @click="confirmDeleteStock">
            <b>{{ this.$t("confirmDialog.yes") }}</b>
          </v-btn>
        </v-card-actions>
        
        <v-card-actions v-if="getMenuId==4">
          <v-spacer></v-spacer>
          <v-btn text @click="close">{{ this.$t("confirmDialog.no") }}</v-btn>
          <v-btn color="primary" text @click="confirmDeleteMaster">
            <b>{{ this.$t("confirmDialog.yes") }}</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import { isEmptyOrNull } from "@/util/StringUtil";
import { handleException } from "@/util/ExceptionUtil";
import { mapActions, mapGetters } from "vuex";
import { APIException } from "@/exception/APIException";
import { CustomException } from "@/exception/CustomException";
import { NotificationModel } from "@/models/NotificationModel";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import APISparePartService from "@/services/APISparePartService";
import APIMasterService from "@/services/APIMasterService";
import SparePartFilter from "@/components/SparePartFilter.vue";

export default {
  name: "SparePartTaskView",
  components: {
    ConfirmDialog,
    SparePartFilter,
  },
  inject: ["loadingDialog"],
  data() {
    return {
      headersHistory: [
        { text: this.$t("SPARE_PART_REQ_ID"), value: "SPARE_PART_REQ_ID", sortable: false },
        { text: this.$t("SPARE_PART_REQ_DATE"), value: "SPARE_PART_REQ_DATE", sortable: false },
        { text: this.$t("GOT_SPARE_PART_DATE"), value: "GOT_SPARE_PART_DATE", sortable: false },
        { text: this.$t("JOB_TYPE"), value: "JOB_TYPE", sortable: false },
        { text: this.$t("JOB_REF"), value: "JOB_REF", sortable: false },
        { text: this.$t("REQ_BY_USER_FULLNAME"), value: "REQ_BY_USER_FULLNAME", sortable: false },
        { text: this.$t("APPROVE_DATE"), value: "APPROVE_DATE", sortable: false },
        { text: this.$t("APPROVED_BY_USER_FULLNAME"), value: "APPROVED_BY_USER_FULLNAME", sortable: false },
        { text: this.$t("STORE_APPROVED_BY_FULLNAME"), value: "STORE_APPROVED_BY_FULLNAME", sortable: false },
        { text: this.$t("STORE_APPROVED_DATE"), value: "STORE_APPROVED_DATE", sortable: false },
      ],
      headersStock: [
        { text: this.$t("PO_ID"), value: "PO_ID", sortable: false },
        { text: this.$t("SPARE_PART_STOCK_ID"), value: "SPARE_PART_STOCK_ID", sortable: false },
        { text: this.$t("SPARE_PART_ID"), value: "SPARE_PART_ID", sortable: false },
        { text: this.$t("RECEIVE_AMONUT"), value: "RECEIVE_AMONUT", sortable: false },
        { text: this.$t("TOTAL_AMONUT"), value: "TOTAL_AMONUT", sortable: false },
        { text: this.$t("RESERVED_AMONUT"), value: "RESERVED_AMONUT", sortable: false },
        { text: this.$t("RESERVED_AMONUT"), value: "RESERVED_AMONUT", sortable: false },
        { text: this.$t("RECEIVE_DATE"), value: "RECEIVE_DATE", sortable: false },
        { text: this.$t("CREATE_DATE"), value: "CREATE_DATE", sortable: false },
        { text: this.$t("CREATE_BY_USER"), value: "CREATE_BY_USER", sortable: false },
      ],
      headersMaster: [
        { text: this.$t("SPARE_PART_ID"), value: "SPARE_PART_ID", sortable: false },
        { text: this.$t("COMPANY_ID"), value: "COMPANY_ID", sortable: false },
        { text: this.$t("SPARE_PART_CODE"), value: "SPARE_PART_CODE", sortable: false },
        { text: this.$t("SPARE_PART_NAME"), value: "SPARE_PART_NAME", sortable: false },
        { text: this.$t("PRICE"), value: "PRICE", sortable: false },
        { text: this.$t("CREATE_DATE"), value: "CREATE_DATE", sortable: false },
        { text: this.$t("CREATE_BY_USER"), value: "CREATE_BY_USER", sortable: false },
      ],
      footerProps: {
        itemsPerPageAllText: this.$t("all"),
        itemsPerPageText: this.$t("rowsPerPage"),
      },
      search: null,
      page: 1,
      loading: false,
      options: {
        itemsPerPage: 10,
      },
      showConfirmDialogHistory: {
        val: false,
      },
      showConfirmDialogStock: {
        val: false,
      },
      showConfirmDialogMaster: {
        val: false,
      },
      dialogGetOtp: false,
      userOtp: "",
      userRef: "",
      tableSparePart: {
        selected: [],
      },
      deleteBySelected: false,
    };
  },
  computed: {
    ...mapGetters({
      listAllCompany: "company/getAllCompany",
      listSparePartHistory: "sparepart/getListSparePartHistory",
      listSparePartStock: "sparepart/getListSparePartStock",
      listSparePartMaster: "sparepart/getListSparePartMaster",
      isLoading: "sparepart/isLoading",
      getStartDate: "sparepart/getStartDate",
      getEndDate: "sparepart/getEndDate",
      getCompanyId: "sparepart/getCompanyId",
      getMenuId: "sparepart/getMenuId",
    })
  },
  mounted() {
    this.getListCompany();
  },
  methods: {
    ...mapActions({
      setNotification: "notification/setSnackbar",
    }),
    ...mapActions({
      getListCompany: "company/getListCompany",
    }),
    // onDisplayInfoClick() {
    //   this.search = null;
    //   this.onPrepareGetReqHistoryTaskByComId();
    // },
    onPrepareRemoveAllHistoryTask() {
      this.showConfirmDialogHistory.val = true;
    },
    onPrepareRemoveAllStockTask() {
      this.showConfirmDialogStock.val = true;
    },
    onPrepareRemoveAllMasterTask() {
      this.showConfirmDialogMaster.val = true;
    },
    async onRemoveAllSparePartHistoryTask() {
      try {
        this.showConfirmDialogHistory.val = false;
        this.loadingDialog.val = true;
        await APIMasterService.getOtpSparePartHistory()
          .then((res) => {
            this.dialogGetOtp = true;
            this.loadingDialog.val = false;
            this.userRef = res.data[0].REF_ID;
          })
          .catch((err) => {
            throw new APIException(err.response.status, err.response.data);
          });
      } catch (err) {
        this.loadingDialog.val = false;
        this.setNotification(new NotificationModel(handleException(err), "red"));
      }
    },
    async onRemoveAllSparePartStockTask() {
      try {
        this.showConfirmDialogStock.val = false;
        this.loadingDialog.val = true;
        await APIMasterService.getOtpSparePartStock()
          .then((res) => {
            this.dialogGetOtp = true;
            this.loadingDialog.val = false;
            this.userRef = res.data[0].REF_ID;
          })
          .catch((err) => {
            throw new APIException(err.response.status, err.response.data);
          });
      } catch (err) {
        this.loadingDialog.val = false;
        this.setNotification(new NotificationModel(handleException(err), "red"));
      }
    },
    async onRemoveAllSparePartMasterTask() {
      try {
        this.showConfirmDialogMaster.val = false;
        this.loadingDialog.val = true;
        await APIMasterService.getOtpSparePartMaster()
          .then((res) => {
            this.dialogGetOtp = true;
            this.loadingDialog.val = false;
            this.userRef = res.data[0].REF_ID;
          })
          .catch((err) => {
            throw new APIException(err.response.status, err.response.data);
          });
      } catch (err) {
        this.loadingDialog.val = false;
        this.setNotification(new NotificationModel(handleException(err), "red"));
      }
    },
    async confirmDeleteHistory() { //! ลบประวัติอะไหล่
      let otp = this.userOtp;
      let ref = this.userRef;

      try {
        this.loadingDialog.val = true;
        let payloads = {};
        
          payloads = {
            userOtp: otp,
            userRef: ref,
          };
          await APISparePartService.removeAllRequesHistoryTask(this.getCompanyId, this.getStartDate, this.getEndDate, payloads)
            .then((res) => {
              console.log("ยิงเส้นลบสำเร็จ");
              this.dialogGetOtp = false;
              this.setNotification(new NotificationModel(res.data.message));
              // this.onPrepareGetReqHistoryTaskByComId();
              this.loadingDialog.val = false;
              this.userOtp = "";
            })
            .catch((error) => {
              this.loadingDialog.val = false;
              throw new CustomException(error.response.data.message);
            });

      } catch (err) {
        this.setNotification(new NotificationModel(handleException(err), "red"));
      }
    }, 
    async confirmDeleteStock() { //! ลบสต๊อกอะไหล่
      let otp = this.userOtp;
      let ref = this.userRef;

      try {
        this.loadingDialog.val = true;
        let payloads = {};
        
          payloads = {
            userOtp: otp,
            userRef: ref,
          };
          await APISparePartService.removeAllStockTask(this.getCompanyId, this.getStartDate, this.getEndDate, payloads)
            .then((res) => {
              console.log("ยิงเส้นลบสำเร็จ");
              this.dialogGetOtp = false;
              this.setNotification(new NotificationModel(res.data.message));
              // this.onPrepareGetReqHistoryTaskByComId();
              this.loadingDialog.val = false;
              this.userOtp = "";
            })
            .catch((error) => {
              this.loadingDialog.val = false;
              throw new CustomException(error.response.data.message);
            });

      } catch (err) {
        this.setNotification(new NotificationModel(handleException(err), "red"));
      }
    }, 
    async confirmDeleteMaster() {  //! ลบรายชื่ออะไหล่
      let otp = this.userOtp;
      let ref = this.userRef;

      try {
        this.loadingDialog.val = true;
        let payloads = {};
        
          payloads = {
            userOtp: otp,
            userRef: ref,
          };
          await APISparePartService.removeAllMasterTask(this.getCompanyId, this.getStartDate, this.getEndDate, payloads)
            .then((res) => {
              console.log("ยิงเส้นลบสำเร็จ");
              this.dialogGetOtp = false;
              this.setNotification(new NotificationModel(res.data.message));
              // this.onPrepareGetReqHistoryTaskByComId();
              this.loadingDialog.val = false;
              this.userOtp = "";
            })
            .catch((error) => {
              this.loadingDialog.val = false;
              throw new CustomException(error.response.data.message);
            });

      } catch (err) {
        this.setNotification(new NotificationModel(handleException(err), "red"));
      }
    }, 
    close() {
      this.dialogGetOtp = false;
      this.loadingDialog.val = false;
    },
  },
};
</script>
<style scoped>
#sparePart {
  height: 100%;
}
</style>
